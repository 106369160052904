import chatMemberType from "../helpers/types/chatMember";
import { darkColors } from '../helpers/colors';

const RoomMemberRow = (props: { member: chatMemberType, startConversation: Function, isOnline: boolean }) => {
    const { member, startConversation, isOnline } = props;

    const generateShortName = (name: String): String => {
        return [name[0], name[name.length - 1]].join('').toUpperCase();
    }

    const getBgHexaColorFromName = (name: String): String => {
        const index = name.split('').reduce((carry, val) => { return carry += val.charCodeAt(0) }, 0) % darkColors.length
        return darkColors[index];
    }

    return (
        <>
            <div className='bg-primary-50 hover:bg-primary-100 dark:bg-primary-900 dark:hover:bg-primary-800 rounded-xl my-1 cursor-pointer flex transition' onClick={() => startConversation(member)}>
                <div className='relative m-2 p-2 text-center align-middle border border-primary-300 rounded-full w-10 h-10 text-primary-50' style={{ backgroundColor: getBgHexaColorFromName(member.pseudoName).toString() }}>
                    {
                        isOnline
                            ? <p className='top-0 right-0 absolute w-3 h-3 rounded-full bg-emerald-500'></p>
                            : <p className='top-0 right-0 absolute w-3 h-3 rounded-full bg-amber-500'></p>
                    }
                    {generateShortName(member.pseudoName)}
                </div>
                <div className='my-auto flex'>
                    <span className=''>{member.pseudoName.split('')[0].toUpperCase() + member.pseudoName.split('').slice(1).join('')}</span>
                    {
                        member.unreadMessages.length
                        ? <span className='ml-1 italic'>({member.unreadMessages.length})</span>
                        : ''
                    }
                </div>
            </div>
        </>
    )
}

export default RoomMemberRow;