import { StrictMode, createContext, useEffect, useRef, useState } from 'react';
import NavBar from './components/navbar';
import pseudoNameType from './helpers/types/pseudoName';
import chatMemberType from './helpers/types/chatMember';
import './App.css';
import { darkColors } from './helpers/colors';
import Conversation from './components/conversation';
import Peer, { DataConnection } from 'peerjs';
import { ArrowForward, RefreshOutlined } from '@mui/icons-material';

import { useTranslation } from "react-i18next";
import './i18n';
import PeerClient from './helpers/peerClient';
import RoomMemberRow from './components/RoomMemberRow';

const ThemeContext = createContext('dark');
export { ThemeContext };

function App() {
  const [sessionCheck, setSessionCheck] = useState(true);

  const pseudonameInput = useRef<HTMLInputElement>(null);

  const [pseudoName, setPseudoName] = useState<pseudoNameType>({
    value: '',
    hash: '',
    is_confirmed: false, // When the user press 'join chat room' button
  });

  const [darkMode, setDarkMode] = useState<string | null>('dark');


  const [pseudoNameSearch, setPseudoNameSearch] = useState('');
  const [roomMembers, setRoomMembers] = useState<chatMemberType[]>([]);

  const [chosenMember, setChosenMember] = useState<chatMemberType | null>(null);

  const [peer, setPeer] = useState<Peer | null>(null);

  const PEER_JS_HOST = process.env.REACT_APP_PEER_JS_HOST;
  const PEER_JS_PORT: number | null = parseInt(process.env.REACT_APP_PEER_JS_PORT ?? '');

  const [contactsList, setContactsList] = useState<chatMemberType[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    // Get username from localStorage
    const peerID = localStorage.getItem('pseudoname');
    if (peerID) {
      setPseudoName((prev) => ({
        value: PeerClient.UnhashString(peerID),
        hash: peerID,
        is_confirmed: true,
      }));
    }

    // Dark mode detection
    const _darkMode = localStorage.getItem('darkMode');
    setDarkMode(_darkMode ? _darkMode : darkMode);


    detectLang();

    if (peerID) {
      initPeer(peerID);
    }

    setSessionCheck(false);


    const intervalID = setInterval(() => {
      if (peer && roomMembers) {
        clearInterval(intervalID);
        checkForOpenConversation();
      }
    }, 200);

    // Retrieve contact list
    restoreContactsList();
    setFirstLoad(false);
    // Clean up
    return (() => {
      peer?.destroy();
      setPeer(null)
      // peerConnection?.close();
      // setPeerConnection(null)
    })
  }, []);


  const initPeer = (peerID: string) => {
    if (peer) return;

    const _peer = new Peer(`${peerID}`, {
      host: PEER_JS_HOST,
      port: PEER_JS_PORT == 443 ? 443 : 80,
      path: '/',
      secure: true,
    });

    _peer.on('open', (id) => {
      if (!peer) {
        setPeer((peer) => _peer);
        checkForOnlineMembers(_peer, true);
      }

      if (!chosenMember) {
        _peer.on("connection", (conn) => {
          conn.on('data', (data: any) => {
            processIncomingData(data, conn);
          })
        });
      }
    })
  }


  const processIncomingData = (data: any, connection: DataConnection) => {
    if (data.type == 'chat_message' && data.sender !== chosenMember?.pseudoNameHash) {
      const message = {
        id: data.id,
        text: data.text,
        sender: data.sender,
        sent_at: data.sent_at,
      };
      setRoomMembers((prev) => prev.map(member => member.pseudoNameHash == data.sender ? { ...member, unreadMessages: [...member.unreadMessages, message] } : member));
    } else if (data.type == 'new_member') {
      const member: chatMemberType = {
        pseudoName: PeerClient.UnhashString(data.sender),
        pseudoNameHash: data.sender,
        peerConnection: { dataConnection: connection, connectionId: connection.connectionId },
        unreadMessages: []
      }
      setRoomMembers([...roomMembers, member]);
    }
  }


  const checkForOpenConversation = () => {
    // Detect if the conversation has already been initialized
    const chatMember = localStorage.getItem('chatMember');
    let chatMemberPseudoName = '';
    if (chatMember) {
      chatMemberPseudoName = JSON.parse(chatMember).pseudoName;
      const index = roomMembers.findIndex(member => member.pseudoName === chatMemberPseudoName);
      if (index >= 0) {
        startConversation(roomMembers[index]);
      }
    }
  }

  const pingPeer = (member: chatMemberType) => {
    if (!peer) return alert('You\'re not connected to the Server! Please try again');

    // Check if there's any open connection with the subject member
    let peerConn: { dataConnection: DataConnection | null, connectionId: string } = { dataConnection: null, connectionId: '' };
    if (member.peerConnection?.dataConnection) {
      peerConn = member.peerConnection;
    } else {
      const _conn = peer.connect(`${member.pseudoNameHash}`);
      peerConn.dataConnection = _conn;
      peerConn.connectionId = _conn.connectionId;
    }

    const doAfterConnectionEstablished = () => {
      // Set the peerConnection
      member.peerConnection = peerConn;
      // Mark unread messages as read if there're
      member.unreadMessages = [];
      setRoomMembers((members) => members.map(m => m.pseudoNameHash != member.pseudoNameHash ? m : member));

      setChosenMember(member);

      // send last message delivery report if there's
      const memberIndex = roomMembers.findIndex(x => x.pseudoNameHash === member.pseudoNameHash);
      if (memberIndex >= 0 && roomMembers[memberIndex].unreadMessages.length) {
        const lastMessage = roomMembers[memberIndex].unreadMessages.sort((a, b) => a.id > b.id ? -1 : 1)[0];
        const payload = {
          type: 'delivery_report',
          id: lastMessage.id.toString(),
          text: lastMessage.text,
          sent_at: lastMessage.sent_at,
          sender: member.pseudoNameHash,
          receiver: peer?.id,
        };
        // connection.send(payload);
      }
    }

    if (peerConn.dataConnection?.open) {
      doAfterConnectionEstablished();
    } else {
      peerConn?.dataConnection?.on('open', () => {
        doAfterConnectionEstablished();
      });
    }
  }

  const [refreshingMembers, setRefreshingMembers] = useState(false);
  const checkForOnlineMembers = async (peer: Peer | null, shouldExposePeer = false) => {
    setRefreshingMembers(true);
    const protocol = PEER_JS_PORT == 443 ? 'https' : 'hhtp';
    const PORT = PEER_JS_PORT ?? 80;
    fetch(`${protocol}://${PEER_JS_HOST}:${PORT}/peers/peers`)
      .then(res => {
        res.json().then((data: String[]) => {

          // Retrieve currently online members
          // Extract the members they're still online (residents) by keeping their unreadMessages data
          const residents = roomMembers.filter(m => data.includes(m.pseudoNameHash));
          const residentsIDs = residents.map(m => m.pseudoNameHash);

          // Extract only new members
          const newMembers: chatMemberType[] = data.filter(id => !residentsIDs.includes(id)).map(x => {
            return { pseudoName: PeerClient.UnhashString(x), pseudoNameHash: x, unreadMessages: [], peerConnection: { dataConnection: null, connectionId: '' } };
          });

          // Spread them to the new state
          setRoomMembers([...residents, ...newMembers]);
          setRefreshingMembers(false);

          if (peer) {
            exposeToOtherPeers(peer, new Set([...residents, ...newMembers]));
          }
          // checkForOpenConversation();
        })
      })
      .catch(err => {
        console.warn('err', err);
        setRefreshingMembers(false);
      })
  };

  const joinChatRoom = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (pseudoName.value.length < 4) {
      alert('Pseudo name too short, minimum 4 characters!');
      if (pseudonameInput && pseudonameInput.current) pseudonameInput.current.focus();
      return
    }

    const peerID = PeerClient.HashString(pseudoName.value, true);
    setPseudoName((prev) => ({ ...prev, is_confirmed: true, hash: peerID.toString() }));
    localStorage.setItem('pseudoname', peerID.toString());

    initPeer(peerID.toString());
  };

  const exposeToOtherPeers = (peer: Peer, onlineMembers: Set<chatMemberType>) => {
    const payload = {
      type: 'new_member',
      text: null,
      id: peer.id,
      sender: peer.id,
      sent_at: null,
    };

    const members: chatMemberType[] = [];
    onlineMembers.forEach(member => {
      if (peer.id !== member.pseudoNameHash && !member.peerConnection?.dataConnection?.open) {
        const conn = peer?.connect(member.pseudoNameHash.toString());
        conn.on('data', (data:any) => {
          processIncomingData(data, conn);
        })
        conn.on('open', () => {
          conn.send(payload);
          members.push({ ...member, peerConnection: { dataConnection: conn, connectionId: conn.connectionId } });
        })
      }
    })

    setTimeout(() => {
      setRoomMembers(members);
    }, 1000);
  }

  const startConversation = (member: chatMemberType) => {
    // peerConnect should not be stored into localStorage
    const localStorageMember = { ...member, peerConnection: { dataConnection: null, connectionId: '' } };
    localStorage.setItem('chatMember', JSON.stringify(localStorageMember));

    pingPeer(member);

    saveToContactList(member);
  }

  //[ Contacts list history
  const saveToContactList = (contact: chatMemberType) => {
    const index = contactsList.findIndex(x => x.pseudoNameHash === contact.pseudoNameHash);
    if (index >= 0) return;

    const newState = [...contactsList, { ...contact, peerConnection: { dataConnection: null, connectionId: contact.peerConnection.connectionId } }];
    setContactsList(newState);
    localStorage.setItem('contactLists', JSON.stringify(newState));
  }

  const restoreContactsList = () => {
    // This should only executes on first load
    if (!firstLoad) return;

    const old = localStorage.getItem('contactLists');
    if (!old) return;

    const __ = JSON.parse(old ?? '');
    const list: chatMemberType[] = __.map((item: any) => ({
      pseudoName: item.pseudoName,
      pseudoNameHash: item.pseudoNameHash,
      peerConnection: { dataConnection: null, connectionId: '' },
      unreadMessages: item.unreadMessages.length < 1 ? [] : item.unreadMessages.map((m: any) => ({
        id: m.id,
        text: m.text,
        sent_at: m.sent_at,
      })),
    }));

    setContactsList(list);
  }
  //]

  const leaveConversation = () => {
    if (!chosenMember) return;
    localStorage.removeItem('chatMember');
    setChosenMember(null);
  }

  const toggleDarkMode = () => {
    const newVal = (darkMode === 'dark') ? 'light' : 'dark';
    setDarkMode(prev => (newVal));
    localStorage.setItem('darkMode', newVal);
  }

  const renderPseudoNameForm = () => {
    if (pseudoName.is_confirmed) return;
    return (
      <form className='p-4 h-screen md:w-1/3 m-auto' onSubmit={(el) => joinChatRoom(el)}>
        <div className='my-2'>
          <label className="block p-1 my-2 text-base" htmlFor='pseudoname' >{t('typePseudoName')}</label>
          <input className="block rounded-md py-2 px-1 outline-0 border-b border-primary-500 dark:bg-primary-700" ref={pseudonameInput} type='text' name="pseudoname" id="pseudoname" value={pseudoName.value.toString()} onChange={(e) => setPseudoName({ ...pseudoName, value: e.target.value })} />
        </div>

        <div className=''>
          <button type='submit' className='bg-primary-300 text-primary-900 hover:bg-primary-400 dark:bg-primary-700 dark:text-primary-100 px-4 py-2 rounded-sm dark:hover:bg-primary-800 active:bg-primary-900 transition-all outline-none'>
            <span className=''>{t('joinChat')}</span>
            <ArrowForward className='' />
          </button>
        </div>
      </form>
    );
  }


  const filterRoomMember = (member: chatMemberType) => {
    return (member.pseudoName != pseudoName.value && member.pseudoName.trim().includes(pseudoNameSearch));
  }

  const renderChatRoomMembers = () => {
    if (!pseudoName.is_confirmed || chosenMember) return <></>;

    const absentMembers = contactsList.filter(x => !(roomMembers.map(y => y.pseudoNameHash)).includes(x.pseudoNameHash));

    return (
      // Container
      <div className='p-4 h-screen md:w-1/3 m-auto'>
        <h2 className='text-xl text-center flex mb-4'>
          <span className=''>{t('onlineMembers')}</span>
          <button onClick={() => checkForOnlineMembers(peer)} title={t('checkNewMembers')}
            className={['ml-2 dark:text-primary-300 dark:hover:text-primary-100 text-primary-700 hover:text-primary-950 transition-all ', refreshingMembers ? 'rotate' : ''].join('')}>
            <RefreshOutlined className='' />
          </button>
        </h2>

        {/* Search members */}
        <div className="">
          <input onChange={e => setPseudoNameSearch(e.target.value)} value={pseudoNameSearch} placeholder={t('pseudoNameSearch')}
            className='w-full mb-2 rounded-md p-2 pl-1 outline-0 border-0 bg-primary-50 hover:bg-primary-100 dark:bg-primary-900 dark:hover:bg-primary-800'
          />
        </div>
        {/* Online members */}
        {
          [...roomMembers, ...absentMembers].filter(x => filterRoomMember(x)).length < 1
            ? <div className='w-full text-center py-4'>
              <p className=''>{t('roomMemberIsEmpty')}</p>
            </div>
            : roomMembers.filter(x => filterRoomMember(x)).map((member, index) => (
              <RoomMemberRow isOnline={true} member={member} key={index} startConversation={startConversation} />
            ))
        }

        {/* Old conversations history */}
        {
          absentMembers.filter(x => filterRoomMember(x)).map((member, index) => (
            <RoomMemberRow isOnline={false} member={member} key={index} startConversation={startConversation} />
          ))
        }
      </div>
    );
  }

  //[
  /**
   * Internationalization
   */
  const { t, i18n: { changeLanguage, language } } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language)
  const handleChangeLanguage = (newLanguage: string) => {
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
    localStorage.setItem('_lang', newLanguage);
  }

  const detectLang = () => {
    const lang = localStorage.getItem('_lang');
    setCurrentLanguage(lang ?? 'en');
    changeLanguage(lang ?? 'en');
  }
  //]

  return (
    <StrictMode>
      <ThemeContext.Provider value={darkMode ? darkMode : 'dark'}>
        <div className={["bg-white dark:bg-primary-950 h-screen m-0 p-0 relative", darkMode === 'dark' ? 'dark' : ''].join(' ')}>
          {
            sessionCheck ? <div className=''>{t('sessionCheck')}</div>
              :
              <div className='bg-white text-primary-900 dark:bg-primary-950 dark:text-primary-50'>
                {/* Navbar */}
                <NavBar handleChangeLanguage={handleChangeLanguage} pseudoName={pseudoName} toggleDarkMode={toggleDarkMode} />

                {/* Pseudo name form */}
                {renderPseudoNameForm()}

                {/* Chat room members */}
                {renderChatRoomMembers()}


                {
                  (chosenMember) ? <Conversation leaveConversation={leaveConversation} member={chosenMember} pseudoName={pseudoName} peer={peer} /> : <></>
                }
              </div>
          }
        </div>
      </ThemeContext.Provider>
    </StrictMode>
  );
}


export default App;
