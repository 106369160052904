import { KeyboardEvent, UIEvent, useContext, useEffect, useRef, useState } from "react";
import chatMemberType from "../helpers/types/chatMember";
import messageType from '../helpers/types/message';
import { ArrowBack, Check, SendOutlined } from '@mui/icons-material'
import { ThemeContext } from "../App";
import Peer from "peerjs";
import { DataConnection } from "peerjs";
import pseudoNameType from "../helpers/types/pseudoName";
import { useTranslation } from "react-i18next";
import PeerClient from "../helpers/peerClient";
import { FallingLines, Grid, Hourglass, Rings, ThreeDots } from 'react-loader-spinner';
import toast, { Toaster } from 'react-hot-toast';
import toastNow from "../helpers/toast";
// Audio notifs sounds
import incommingMessageSound from '../assets/audio/AKS3.m4a';
import outgoingMessageSound from '../assets/audio/AKS1.m4a';

const Conversation = (props: {
    member: chatMemberType,
    leaveConversation: Function,
    peer: Peer | null,
    pseudoName: pseudoNameType,
}) => {

    const [message, setMessage] = useState<string | null>();
    const [messages, setMessages] = useState<messageType[]>([]);
    const [messageContent, setMessageContent] = useState('');
    const darkMode = useContext(ThemeContext)
    const { t } = useTranslation();
    const [pagination, setPagination] = useState({ limit: 20, offset: 0, count_reached: false });;
    const [loadingMessages, setLoadingMessages] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        // Establish connection between peers
        setLocalPeerConn(props.member.peerConnection?.dataConnection);

        if (props.member.peerConnection?.dataConnection) {
            props.peer?.on("connection", (conn) => {
                conn.on('data', (data: any) => {
                    processIncomingData(data);
                })
            });

            props.member.peerConnection?.dataConnection?.on('data', (data: any) => {
                processIncomingData(data);
            })
        }


        restoreConversationHistory().then((data: messageType[]) => {
            setMessages([...messages, ...data]);
            if (pagination.offset === 0)
                scrollConversationToBottom();
        })
            .catch((err) => { console.warn('Can\'t restore conversation history', err) });


        const el = conversationHistoryRef.current;
        if (el) {
            el.onscroll = handleConversationScroll;
        }


        if (textAreaRef.current) {
            textAreaRef.current.focus();
        }

        // Clean up
        return (() => {
            // 
        })
    }, []);

    const conversationHistoryRef = useRef<HTMLDivElement>(null);
    const restoreConversationHistory = async (): Promise<messageType[]> => {
        if (loadingMessages) return Promise.reject('Another request is being under execution!');

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URI;
        const url = new URL(`${apiBaseUrl}/messages/`);
        url.searchParams.append('member1', props.member.pseudoNameHash.toString());
        url.searchParams.append('member2', props.pseudoName.hash.toString());
        url.searchParams.append('limit', pagination.limit.toString());
        url.searchParams.append('offset', pagination.offset.toString());
        const jsonHistory = await fetch(url);
        setLoadingMessages(true);
        if (!jsonHistory.ok) {
            setLoadingMessages(false);
            return new Promise((resolve, reject) => {
                reject('Something went wrong while fetching conversation history');
            });
        }

        const data = await jsonHistory.json();
        if (!data.results.length) {
            setPagination((prev) => ({ limit: prev.limit, offset: prev.offset, count_reached: true }));
        }

        const __: messageType[] = data.results?.map((item: any) => ({
            id: item.id,
            content: item.text,
            sender: PeerClient.UnhashString(item.sender),
            receiver: PeerClient.UnhashString(item.receiver),
            sent_at: new Date(item.sent_at),
            read_at: item.read_at ? new Date(item.read_at) : null,
            updated_at: item.updated_at ? new Date(item.updated_at) : null,
        }));

        setLoadingMessages(false);
        return new Promise((resolve, reject) => {
            const ID = setTimeout(() => {
                resolve(__);
            }, 1);
        });
    }


    //// Conversation history
    const persistConversationHistory = (history: messageType[]) => {
        localStorage.setItem(`${props.member.pseudoName}conversationHistory`, JSON.stringify(history));
    }
    ////


    const confirmLeave = () => {
        props.leaveConversation();
    }

    const formatDate = (date: Date): string => {
        const options: Intl.DateTimeFormatOptions = { hour: "numeric", minute: "2-digit" };
        let formattedTime = date.toLocaleTimeString(navigator.language, options);
        return formattedTime;
    }

    // Audio notifs
    const outgoingMessageRef = useRef<HTMLAudioElement>(null);
    const incommingMessageRef = useRef<HTMLAudioElement>(null);

    const sendMessage = async () => {
        // Check message length
        if (messageContent.trim().length < 1) {
            return;
        }

        appendMessage({ message: messageContent, id: 0 }, false); // the id:0 here will never be use since the id will be returned from the API
        // Store message at the backend side
        postMessage({
            text: messageContent,
            receiver: props.member.pseudoNameHash,
            sender: props.pseudoName.hash,
        });

        setMessageContent((prev) => (''));
    };

    const [postingMessageToBackend, setPostingMessageToBackend] = useState<boolean>(false);
    const postMessage = async (payload: { text: String, sender: String, receiver: String }) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URI;
        setPostingMessageToBackend(true);
        const req = await fetch(`${apiBaseUrl}/messages/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                text: payload.text,
                sender: payload.sender,
                receiver: payload.receiver,
            })
        });

        if (req.ok) {
            const data = await req.json();
            // Append the message since it's well stored
            setMessages((prev) => [...prev, {
                id: data.id,
                content: data.text,
                sender: PeerClient.UnhashString(data.sender),
                receiver: PeerClient.UnhashString(data.receiver),
                sent_at: new Date(data.sent_at),
                read_at: null,
                updated_at: null,
            }]);

            // Send message to peer
            const messageData = {
                type: 'chat_message',
                text: messageContent,
                id: data.id,
                sender: payload.sender,
                sent_at: new Date(data.sent_at),
            };

            const re = props.member.peerConnection?.dataConnection?.send(messageData);

            // Play sound notif
            const audioEl = outgoingMessageRef.current;
            if (audioEl) {
                audioEl.play()
                    .then(() => { })
                    .catch(() => { })
            }
        } else {
            toast.error('Message could not be sent!')
        }

        setPostingMessageToBackend(false);
    }

    const processIncomingData = (data: any) => {
        // Check for deliveryReport first
        if (data.type == 'delivery_report') {
            const id: number = parseInt(data.id);
            reportDelivery(id);
        } else if (data.type == 'chat_message') {
            appendMessage({ message: data.text, id: data.id }, true);

            // Display new message popup notification if the user has scrolled up in the conversation
            const el = conversationHistoryRef.current;
            if (el && (el.scrollHeight - el.scrollTop - el.clientHeight >= 200)) {
                toastNow({ message: data.text });
            }
        }

    }

    const reportDeliveryToApi = async (payload: { messageID: number, read_at: Date }) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URI;
        const req = await fetch(`${apiBaseUrl}/messages/?id=${payload.messageID}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            method: 'PUT',
            body: JSON.stringify({
                read_at: payload.read_at,
            })
        });

        if (!req.ok) {
            toast.error('We could not send delivery report!')
        }
    }


    const uniqueMessages = (data: messageType[]) => {
        return data.filter((val, index, arr) => arr.findIndex(a => (a.id === val.id && a.content === val.content)) === index);
    }

    //[ Conversation scrolling
    const handleConversationScroll = () => {
        const div = conversationHistoryRef.current
        if (!div) return;

        /**
         * When thinking of prefetching more messages before the user reaches the top of conversation, consider this
         * First time will fetch more messages when the scrollTop is equal to 0
         * After that will launch the fetch if the scrollTop is smaller than 50
         * This is to prevent undesired API requests
         */
        if (div.scrollTop == 0 && !pagination.count_reached) {
            setPagination((prev) => ({ limit: prev.limit, offset: prev.offset + prev.limit, count_reached: prev.count_reached }))
        }
    }

    const scrollConversationToBottom = () => {
        const el = conversationHistoryRef.current;
        setTimeout(() => {
            if (el) {
                el.scrollTo({
                    top: el.scrollHeight,
                    left: 0,
                    behavior: "smooth"
                })
            }
        }, 100);
    }
    //]

    const [localPeerConn, setLocalPeerConn] = useState<DataConnection | null>(null);

    const appendMessage = (data: { message: string, id: number }, received: boolean = true) => {
        // Scroll down if the user has not already scrolled up to view the conversation history
        const el = conversationHistoryRef.current;
        if (el && (el.scrollHeight - el.scrollTop - el.clientHeight < 200)) {
            scrollConversationToBottom();
        }

        if (received) {
            const message: messageType = {
                id: data.id,
                content: data.message,
                sender: received ? props.member.pseudoName : props.pseudoName.value,
                receiver: !received ? props.member.pseudoName : props.pseudoName.value,
                sent_at: new Date(),
                read_at: null,
                updated_at: null,
            };

            setMessages((prev) => [...prev, message]);


            // Audio notif
            const audioElem = incommingMessageRef?.current;
            if (audioElem) {
                audioElem?.play()
                    .then(() => { })
                    .catch((err) => { console.warn('Cannot play the incoming message audio', err) })
            }

            // Send read receipt
            sendDeliveryReport(message);
        }
    }

    const [keysDown, setKeysDown] = useState<string[]>([]);
    const handleCompoeMessageKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        setKeysDown((prev) => [...prev, event.key.toLowerCase()]);
        if (keysDown.includes('shift') && event.key.toLowerCase() == 'enter') {
            setKeysDown([]);
            sendMessage()
            setTimeout(() => {
                setMessageContent((prev) => (''));
            }, 200);
        }
    }

    const handleCompoeMessageKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        setKeysDown((prev) => prev.filter(x => x != event.key.toLowerCase()));
    }


    //[ Message Delivery Report
    const reportDelivery = (messageID: number) => {
        // Set the message as read
        setMessages((prev) => prev.map(m => ({ ...m, read_at: new Date() })));
    }

    const sendDeliveryReport = (message: messageType) => {
        // Send delivery report to the peer
        const messageData = {
            type: 'delivery_report',
            text: messageContent,
            id: message.id.toString(),
            sender: message.sender,
            receiver: message.receiver,
            sent_at: message.sent_at,
        };
        props.member.peerConnection?.dataConnection?.send(messageData);
        // Update the backend as well
        reportDeliveryToApi({ messageID: message.id, read_at: new Date() });
    }
    //]


    const renderConversationHistory = () => {
        return (
            <div ref={conversationHistoryRef} className={["my-4 h-5/6 md:h-2/3 p-2 overflow-y-auto relative", `${darkMode}-custom-scrollbar`].join(' ')}>
                <div className="max-w-fit m-auto">
                    <Grid
                        visible={loadingMessages}
                        height="30"
                        width="30"
                        color="#3b82f6"
                        ariaLabel="grid-loading"
                        radius="10.5"
                        wrapperStyle={{}}
                        wrapperClass="grid-wrapper"
                    />
                </div>

                {
                    uniqueMessages(messages).sort((a, b) => a.id > b.id ? 1 : -1).map((message, index) => (
                        <div key={index} className={["w-fit max-w-[80%] rounded-2xl", message.sender == props.pseudoName.value ? 'ml-auto bg-primary-100 dark:bg-primary-900' : 'bg-primary-100 dark:bg-primary-900'].join(' ')}>
                            <div className='my-1 relative flex p-2'>
                                <p className='max-w-max'>{message.content}</p>
                                <div className='mt-auto text-xs italic ml-2'>
                                    <span className="">{formatDate(message.sent_at)}</span>
                                    {
                                        message.sender == props.pseudoName.value
                                            ? <>
                                                <Check className="ml-1" style={{ fontSize: '13px' }} />
                                                {
                                                    message.read_at
                                                        ? <Check className="-ml-2" style={{ fontSize: '13px' }} />
                                                        : ''
                                                }

                                            </>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }


    return (
        <div className='p-4 h-[90vh] pb-8 md:pb-1 md:h-screen md:w-1/3 m-auto bg-primary-10 '>
            <h2 className='text-xl text-center flex py-2 bg-primary-300 dark:bg-primary-900'>
                <ArrowBack className="cursor-pointer mr-2 dark:text-primary-500 hover:text-primary-700 transition" onClick={confirmLeave} />
                <div className="w-full text-center">
                    <div className="w-full text-center flex">
                        <span className="w-1/2 text-right">
                            {props.member.pseudoName.split('')[0].toUpperCase() + props.member.pseudoName.split('').slice(1).join('')}
                        </span>
                        {
                            props.member.peerConnection?.dataConnection?.open
                                ? <p className="ml-0 h-3 w-3 bg-teal-500 rounded-full"></p>
                                : <p className="ml-0 h-3 w-3 bg-amber-500 rounded-full"></p>
                        }
                    </div>
                </div>
            </h2>

            {/* Conversation history */}
            {renderConversationHistory()}

            {/* Compose message */}
            <div className=" w-[90%] md:w-full flex fixed bottom-2 md:relative">
                <textarea ref={textAreaRef} onKeyDown={(e) => handleCompoeMessageKeyDown(e)} onKeyUp={(e) => handleCompoeMessageKeyUp(e)} onChange={e => setMessageContent(e.target.value)} className="w-full outline-none border-none shadow-none rounded-2xl p-2 resize-none text-primary-900 bg-primary-100 dark:bg-primary-800 dark:text-white" placeholder={t('composeMessagePlaceholder')} value={messageContent} />
                <button disabled={postingMessageToBackend} onClick={() => sendMessage()} className="w-12 h-12 ml-2 mt-2 cursor-pointer bg-primary-200 hover:bg-primary-300 dark:bg-primary-700 dark:hover:bg-primary-900 text-center transition">
                    {
                        postingMessageToBackend
                            ? <ThreeDots
                                visible={true}
                                height="20"
                                width="20"
                                color="#3b82f6"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            : <SendOutlined className="" />
                    }
                </button>
            </div>


            {/* Audio sound notifications */}
            <audio ref={outgoingMessageRef} src={outgoingMessageSound} />
            <audio ref={incommingMessageRef} src={incommingMessageSound} />

            {/* Notifications */}
            <Toaster />
        </div>
    )
}

export default Conversation;